#スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing,
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
)

window.onpageshow = (evt) ->
  if evt.persisted
    location.reload()
  return

event =
  all: 'mousedown touchstart pointerdown mouseenter mouseleave'
  type: ''
  start: ''
  enter: ''
  leave: ''
  move: ''
  end: ''
# イベント判定

event.check = (e) ->
  _default = e.type
  event.start = _default
  event.move = if _default == 'pointerdown' then 'pointermove' else if _default == 'touchstart' then 'touchmove' else 'mousemove'
  event.end = if _default == 'pointerdown' then 'pointerup' else if _default == 'touchstart' then 'touchend' else 'click'
  event.enter = if _default == 'pointerenter' then _default else if _default == 'touchenter' then _default else 'mouseenter'
  event.leave = if _default == 'pointerleave' then _default else if _default == 'touchleave' then _default else 'mouseleave'
  return

event.on = (type, elm, fn, delegate) ->
  $(document).off(event.all, elm).on event.all, elm, (e) ->
    e.preventDefault()
    event.check e
    if fn != null
      if e.type == event.enter or e.type == event.leave
        # mouseenter mouseleaveのとき、コールバック関数を即実行
        fn this, e
      else
        if type == 'bind'
          # jQuery $(element).on(event, func);
          $(elm).off(event.end).on event.end, (e) ->
            fn this, e
            return
        else if type == 'live'
          # jQuery $(document).on(event, element, func);
          $(document).off(event.end, elm).on event.end, elm, (e) ->
            fn this, e
            return
        else if type == 'delegate'
          # jQuery $(parentsElement).on(event, element, func);
          $(delegate).off(event.end, elm).on event.end, elm, (e) ->
            fn this, e
            return
    else
      # 引数にコールバック関数が定義されていないときの処理
    return
  return


$(()->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  if $class.indexOf('mail') != 0 or $id.indexOf('contact') == 0
    firstDownFlg = true
    firstUpFlg = false
    $('.header-contents').headroom
      tolerance:
        up:60
        down:50
      offset:0
      classes:
        initial: 'animated'
        pinned: 'slideDown'
        unpinned: 'slideUp'
      onPin: ->
        firstUpFlg = true
        if !firstDownFlg
          return $('.header-contents').removeClass('slideUpFirst')
        return
      onUnpin: ->
      onTop: ->
        firstDownFlg = true
        $('.header-contents').removeClass 'slideDownFirst slideDown pin outreturn up'
      onNotTop: ->
        if firstDownFlg
          $('.header-contents').addClass 'slideUpFirst'
          return firstDownFlg = false
    $(window).scroll ->
      if firstUpFlg and $(this).scrollTop() <= $('.header-contents').height()
      else if  firstUpFlg
        $('.header-contents').addClass 'outreturn'
        return firstUpFlg = false
      if $(this).scrollTop() <= $('.header-contents').height()+40
        $('.header-contents').addClass 'pin'
      else $('.header-contents').removeClass 'pin'
      if $(this).scrollTop() >= $('.header-contents').height()
        $('.header-contents.slideDown').addClass 'up'

  if $class.indexOf('drawer') == 0
    $('.drawer').drawer()
    $('.has-child-title').click ->
      $(this).toggleClass "is-active"
      if $("+.gnavi-child-list", this).css("display") is "none"
        $(this).addClass "is-open"
        $("+.gnavi-child-list", this).slideDown "normal"
      else
        $(this).removeClass "is-open"
        $("+.gnavi-child-list", this).slideUp "normal"
      return



  topBtn = $(".pagetop")
  #最初はボタンを隠す
  topBtn.hide()
  #スクロールが300に達したらボタンを表示させる
  $(window).scroll ->
    if $(this).scrollTop() > 300
      topBtn.fadeIn()
    else
      topBtn.fadeOut()
    return
  topBtn.click ->
    $("body,html").animate
      scrollTop: 0
    , 500
    false

  # $(".has-child-title").click ->
  #   if $("+.gnavi-child-list", this).css("display") is "none"
  #     $(this).addClass "is-open"
  #     $("+.gnavi-child-list", this).slideDown "normal"
  #   else
  #     $(this).removeClass "is-open"
  #     $("+.gnavi-child-list", this).slideUp "normal"
  #   return

  $('.m-anchor-item a[href^="#"]').on('click.smoothScroll'
    (e)->
      $(
        if (navigator.userAgent.indexOf('Opera') isnt -1)
        then (
          if document.compatMode is 'BackCompat'
          then 'body'
          else 'html'
        )
        else 'html,body'
      ).animate({
        scrollTop:$($(this).attr('href')).offset().top - $('.h-navi').height()
      },{
        easing:'easeInOutCirc',
        duration:1000
      })

      e.preventDefault()
      return
  )

  $('.tab-item a[href^="#active-box"]').bind 'touchend', ->
    $('.m-active-box').hide()
    $(this.hash).fadeIn(400)
    $('.tab-item a').parent().removeClass 'is-active'
    $(this).parent().toggleClass 'is-active'
    false
  
)
